import { React, useEffect, useState } from 'react'
import { Button, Modal, Offcanvas } from 'react-bootstrap'
import Buttons from '../button/index.js';
import SignIn from './modalfields/signin.js';
import SignUp from './modalfields/signup.js'
import Cookies from 'universal-cookie';
import { getCustomer, getUserById } from '../../api'
import ContactModal from '../contactmodal/index.js';
import './style.css'
import { FaUser, FaUserPlus } from 'react-icons/fa'
import { RiErrorWarningFill } from 'react-icons/ri'
import Profile from './modalfields/profile/index.js';
import Deposit from './modalfields/deposite/index.js';
import WithDraw from './modalfields/withdraw/index.js';
import Transactions from './modalfields/transactions/index.js';
import Histories from './modalfields/history/index.js';


export default function Modale(props) {
    const cookie = new Cookies()
    const [message, setMessage] = useState('')
    const [user, setUser] = useState([])
    const [SupportModalShow, setSupportModalShow] = useState(false);
    const [isHistory, setIsHistory] = useState(false);

    const handleClose = () => props.setShow(false);



    useEffect(async () => {
        if (cookie.get('id')) {
            const user = getUserById(cookie.get('id'))
            user.then(response => {
                setMessage(response.data.message)
                setUser(response.data)
            })
        }
    }, [])

    return (
        <>
            <Offcanvas show={props.show} onHide={handleClose} style={{ backgroundColor: '#EDEEF0', width: `${isHistory ? "50%" : null}` }}>
                {
                    props.signin &&
                    <>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Sign In</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <SignIn />
                        </Offcanvas.Body>
                    </>
                }
                {props.signup &&
                    <>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                {
                                    SupportModalShow ?
                                        <>Contact Us</>
                                        :
                                        <>Sign Up</>
                                }
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <SignUp Close={props.onHide} setModalShow={props.setModalShow} SupportModalShow={SupportModalShow} setSupportModalShow={setSupportModalShow} />
                        </Offcanvas.Body>

                    </>
                }
                {
                    props.message &&
                    <>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title onClick={() => setSupportModalShow(false)}>
                                Warning
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {
                                SupportModalShow ?
                                    <ContactModal Close={props.onHide} setSupportModalShow={setSupportModalShow} />
                                    :
                                    <div className='warningBody'>
                                        <h5>
                                            {message}
                                        </h5>
                                        <br></br>
                                        <Button variant='primary' onClick={() => setSupportModalShow(true)} className='warningButton'>
                                            Contact Us
                                        </Button>
                                    </div>
                            }
                        </Offcanvas.Body>
                    </>
                }


                {
                    props.profile &&
                    <>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id="contained-modal-title-vcenter">
                                Profile
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Profile />
                        </Offcanvas.Body>
                    </>
                }
                {
                    props.deposite &&
                    <>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id="contained-modal-title-vcenter">
                                Deposit
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Deposit />
                        </Offcanvas.Body>
                    </>
                }
                {
                    props.withdraw &&
                    <>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id="contained-modal-title-vcenter">
                                Withdraw
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <WithDraw />
                        </Offcanvas.Body>
                    </>
                }
                {
                    props.transaction &&
                    <>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id="contained-modal-title-vcenter">
                                Transactions
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Transactions />
                        </Offcanvas.Body>
                    </>
                }
                {
                    props.contact &&
                    <>
                        <Modal.Header>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Contact Us
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ContactModal Close={props.onHide} setSupportModalShow={setSupportModalShow} />
                        </Modal.Body>
                    </>
                }
                {
                    props.history &&
                    <>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id="contained-modal-title-vcenter">
                                History
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Histories setIsHistory={setIsHistory} />
                        </Offcanvas.Body>
                    </>
                }

            </Offcanvas>
        </>
    )
}
