import { Button, Col, Container, Row } from 'react-bootstrap';
import './style.css'
import moment from 'moment';


export default function News({ news }) {

    return (
        <>
            <Container style={{ padding: 0, marginTop: 32 }}>
                <h4 className='newsTitle'>News:</h4>
                {/* <div className='newsWrapper' >
                    {news?.map((data, index) => (
                        <div key={index} className='newsContainer'>
                            <div className="imageDateCatContainer">
                                <img className='newsImage' src={data.enclosures[0].url} alt="news" />
                                <div className='dateCatContainer'>
                                    <p className='newsDate' >{moment(data.published).format('L HH:MM')}</p>
                                    <p className='newsCat' >{data.category}</p>
                                </div>
                            </div>
                            <p className="newsTitle" style={{ fontSize: 12, lineHeight: 1.3, height: '4rem' }}>{data.title}</p>
                            <div className='readMoreButtonContainer'>
                                <Button className='readMoreButton'>
                                    <a
                                        href={data.link}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Read More
                                    </a>
                                </Button>
                            </div>
                        </div>
                    ))
                    }

                </div> */}
                <Row xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    {
                        news?.map((data, index) => (
                            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className='newsContainer'>
                                    <div>
                                        <img src={data.enclosures[0].url} alt="news" width={150} height={100} />
                                    </div>
                                    <div className='newsBody'>
                                        <p className="newsBodyTitle">{data.title}</p>
                                        <div className='newsBodyDateButton'>
                                            <p className='newsDate' >{moment(data.published).format('L HH:MM')}</p>
                                            <Button className='newsReadMoreButton'>
                                                <a
                                                    href={data.link}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Read More
                                                </a>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))
                    }
                </Row>
                <br></br>
            </Container>
        </>
    )
}
