// import { Popover } from 'bootstrap';
import React, { useState } from 'react'
import { Nav, Navbar, Container, Dropdown } from 'react-bootstrap'
import './style.css';
import DropDown from '../dropdown';
import Buttons from '../button';
import Cookies from 'universal-cookie';
import Modale from '../modal';
import { navLinks } from './navLinks';
import useViewport from "../../hook/useViewport";

export default function Navigation() {
    const cookie = new Cookies();
    const [modalShow, setModalShow] = useState(false);
    const { width } = useViewport();
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);

    //className={(width < 600 ? 'mobileNewsContainer' : 'newsContainer')}

    return (
        <div className="navContainer">
            <Container >
                <Navbar expand="md" style={{ padding: 0, }}>
                    <Navbar.Brand href="/">
                        <div className="logo"></div>
                    </Navbar.Brand>
                    <Navbar.Toggle className="hamburgerButton" aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="me-auto" ></Nav>
                        {
                            cookie.get('token') ?
                                <>
                                    <DropDown />
                                </>
                                :
                                <>
                                    <div style={{ marginRight: 16 }}>
                                        <Buttons text='sign in' kind="transparent2" signin={true} />
                                    </div>
                                    <Buttons text='create Account' kind="transparent2" signup={true} />
                                </>

                        }
                    </Navbar.Collapse>
                </Navbar >
            </Container >
        </div>
    )
}
