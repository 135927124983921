import React from 'react'
import './styles.css'
import Cookies from 'universal-cookie';
import { Nav } from 'react-bootstrap';
import { useState } from 'react';
import { navLinks } from '../navigation/navLinks';
import Modale from '../modal';
import Buttons from '../button';

const SportsLinks = () => {
    const cookie = new Cookies();
    const [modalShow, setModalShow] = useState(false);


    return (
        <div className='sprotsLinkWrapper'>
            <div className='sportsLinkTitle'>
                <h5>{!cookie.get('token') ? "Sport Links" : "Actions"}</h5>
            </div>
            <div className='sportsLinkContainer'>
                {
                    !cookie.get('token') ?
                        navLinks?.map(link => (
                            <p className='sportsLink' onClick={() => setModalShow(true)}>{link.link}</p>
                        ))

                        :
                        <div className='actionLinksContainer'>

                            <Buttons text='History' kind="transparent" history={true} />
                            <Buttons text='Deposit' kind="transparent" deposite={true} />
                            <Buttons text='Withdraw' kind="transparent" withdraw={true} />
                            <Buttons text='Transactions' kind="transparent" transaction={true} />
                            <Buttons text='profile' kind="transparent" profile={true} />

                        </div>

                }
            </div>

            <Modale
                show={modalShow}
                onHide={() => setModalShow(false)}
                setShow={setModalShow}
                signin={cookie.get('token') ? false : true}
                message={cookie.get('token') ? true : false}
            />
        </div>
    )
}

export default SportsLinks