export const mainLinks = [
    {
        link: 'Home'
    },
    {
        link: 'Deposit'
    },
    {
        link: 'History'
    },
    {
        link: 'Withdraw'
    },
    {
        link: 'Contact Us'
    },
    {
        link: 'Profile'
    }
]
