import axios from 'axios';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { getResults } from '../src/api';
import './App.css';
import Footer from './components/Footer';
import Auth from './components/auth';
import Navigation from './components/navigation';
import News from './components/news';
import Slideshow from './components/slideshow';
import SportsLinks from './components/sportsLink';
import TableContainer from './components/table/tableContainer';
import BetTypes from './components/types';
import USALeagues from './components/usaLeagues';


function App() {
  const [news, setNews] = useState([]);
  const [games, setGames] = useState([]);

  useEffect(() => {
    axios.get('/api/news')
      .then(response => {
        setNews(response.data.items);
      })
      .catch(error => console.log(error));

    getResults().then(async response => {
      if (response) {
        setGames(response)
      }
    })
      .catch(error => console.log(error));
  }, []);


  return (
    <div style={{ overflowX: 'hidden' }}>
      <Navigation />

      <Row>
        <Col xxl={2} xl={2} lg={2} md={2} sm={2} >
          <div className='sidBarWrapper'>
            <Row xxl={12} xl={12} lg={12} md={12} sm={12}>
              <SportsLinks />
            </Row>
            <Row xxl={12} xl={12} lg={12} md={12} sm={12}>
              <BetTypes />
            </Row>
            <Row xxl={12} xl={12} lg={12} md={12} sm={12}>
              <USALeagues />
            </Row>
            <Row xxl={12} xl={12} lg={12} md={12} sm={12}>
              <Auth />
            </Row>
          </div>
        </Col>
        <Col xxl={10} xl={10} lg={10} md={10} sm={10}>
          <Container>
            <Row>
              <Col>
                <Slideshow />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} >
                <News news={news} setNews={setNews} />
              </Col>
              <Col lg={12} md={12} sm={12}>
                <Row>
                  <TableContainer games={games} />
                </Row>
              </Col>

            </Row>
          </Container>
        </Col>
      </Row>
      <Row>
        <Footer />
      </Row>

    </div >
  );
}

export default App;
