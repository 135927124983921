import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';


import Cookies from 'universal-cookie';
import Modale from '../modal';
import { leagues } from '../usaLeagues/data';
import { mainLinks } from './data';
import './style.css';

export default function Footer() {
    const [modalShow, setModalShow] = useState(false);
    const cookie = new Cookies();

    return (
        <Container className='footerWrapper'>
            <Container>
                <Row className="linksContainer" sm={3} md={3} xs={2} >
                    <Col lg={2} md={3} sm={12} className="socialMediaWrapper" >
                        <Row className='socialMediaContainer'>
                            <h3>Social Media</h3>
                        </Row>
                    </Col>
                    <Col lg={6} md={2} sm={12}></Col>
                    <Col lg={4} md={7} sm={12}>
                        <p className='footerText'>
                            18+ Know When To Stop. Don’t Go Over The Top. Gamble Responsibly. Think! About Your Choices. Check Gambling Help on www.gamblinghelponline.org.au
                        </p>
                    </Col>
                </Row>
                <Row>
                    <div className='mainLinksContainer'>
                        {
                            mainLinks.map((link) => (
                                <p key={link.link} onClick={() => setModalShow(true)} >
                                    {link.link}
                                </p>
                            ))
                        }
                    </div>
                </Row>
                <Row>
                    <div className='leagueLinksContainer'>
                        {
                            leagues.map((league) => (
                                <p key={league.leagueName} onClick={() => setModalShow(true)} >
                                    {league.leagueName}
                                </p>
                            ))
                        }
                    </div>
                </Row>
            </Container>
            <div className="copyRight" >
                <p style={{ margin: 0, paddingTop: 0 }}>Copyright © 2023 - All right reserved.</p>
            </div>
            {
                cookie.get('token') ?
                    cookie.get('messageStatus') === '0' ?
                        null
                        :
                        <Modale
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            message={true}
                            setShow={setModalShow}

                        />
                    :
                    <Modale
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        signin={true}
                        setShow={setModalShow}

                    />

            }
        </Container>
    )
}



