import React from 'react'
import './style.css'
import Cookies from 'universal-cookie';
import { useState } from 'react';
import Modale from '../modal';
import { types } from './types';

const BetTypes = () => {
    const cookie = new Cookies();
    const [modalShow, setModalShow] = useState(false);


    return (
        <div className='betTypesWrapper'>
            <div className='betTypesTitle'>
                <h5>Bet Types</h5>
            </div>
            <div className='betTypesContainer'>
                {types?.map((data) =>
                    <div className='betTypeContainer' onClick={() => setModalShow(true)}>
                        <div style={{ marginRight: 8 }}>
                            {data.icon}
                        </div>
                        <p key={data.id} style={{ cursor: 'pointer' }} className='betTypeText'>{data.title}</p>
                    </div>
                )}
            </div>

            {
                cookie.get('token') ?
                    cookie.get('messageStatus') === '0' ?
                        null
                        :
                        <Modale
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            message={true}
                            setShow={setModalShow}
                        />
                    :
                    <Modale
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        signin={true}
                        setShow={setModalShow}

                    />

            }
        </div>
    )
}

export default BetTypes